:root {
    /* Cores */
    --red: #e0342e;
    --shadow-red: #E2413C;
    --white: #FCFCFC;
    --gray: #f0f0f0;
    --black: #2E2922;
    --fs-description: 20px;

    --red-text: #af0e0e;
    --black-text: #2E2922;
    --white-text: #FCFCFC;

    /* Fontes */
    --h1-font: 'Oswald', sans-serif;
    --h2-font: 'PT Sans', sans-serif;
    --h3-font: 'PT Sans', sans-serif;
    --p-font: 'PT Sans', sans-serif;

    /* Tipografia*/
    --h1-banner: 56px;
    --h1: 44px;
    --h2: 33px;
    --h3: 22px;
    --p: 19px;
    --mobile-h1: 30px;
    --mobile-h2: 27px;
    --mobile-h3: 22px;
    --mobile-p: 18px;
    --h1-line-height: calc(var(--h1) * 1.2);
    --h2-line-height: calc(var(--h2) * 1.5);
    --h3-line-height: calc(var(--h3) * 1.5);
    --p-line-height: calc(var(--p) * 1.5);

    /* Botões*/
    --normal-btn: 44px;
    --large-btn: 54px;
    --extra-large-btn: 64px;
    --border-radius-normal-btn: 5px;
    --border-radius-large-btn: 10px;
    --border-radius-extra-large-btn: 15px;

    /* Sombras */
    --shadow-input: 1px 1px 2px var(--gray);
    --shadow-card: 2px 2px 2px var(--gray);
}

html {
    scroll-behavior: smooth;
    scroll-padding: 80px; /* height of the fixed element*/ 
  }

/* STARTS TIPOGRAPHY */

.h1 {
    font-size: var(--h1);
    line-height: var(--h1-line-height);
    text-align: center;
    font-family: var(--h1-font);
    margin-bottom: 32px;
}

.h1-banner{
    font-size: var(--mobile-h1);
    text-align: center;
} 

.red-text{
    color: var(--red-text);
}

.white-text{
    color: var(--white-text);
}
.black-text {
    color: var(--black-text);
}

.h2 {
    font-size: var(--h2);
    line-height: var(--h2-line-height);
    text-align: center;
    font-family: var(--h2-font);
}

.h3 {
    font-size: var(--h3);
    line-height: var(--h3-line-height);
    text-align: center;
    font-family: var(--h3-font);
    margin-top: 6px;
    margin-bottom: 6px;
}

.p {
    font-size: var(--p);
    line-height: var(--p-line-height);
    font-family: var(--p-font);
    text-align: start;
    margin-bottom: 16px;
    letter-spacing: .2px;
    word-spacing: .1px;
}

.center-text{
    text-align: center;
}

/* ENDS TIPOGRAPHY */
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* start HEADER */

.header {
    display:flex;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    background-color: var(--red);
    color: var(--white);
    z-index: 10;
}
#hamburguer {
    position: absolute;
    right: 0;
    margin-right: 15px;
    color: var(--light);
    top: 50%;
    transform: translateY(-50%);
}

#hamburguer .fa-bars {
    cursor: pointer;
    font-size: 2rem;
}

.nav { 
    position: fixed;
    /*
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    */
    top: 80px;
    margin: 0;
    padding: 0;
    height: calc(100vh - 80px);
    width: 100%;
    z-index: 100;
    background-color: var(--red);
    left: -100%;
    transition: 0.5s;
}

.nav-displayed {
    left: 0;
}

.logo {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 15px;
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
}

.logo a {
    color: var(--white);
}

.logo-img {
    max-width: 100%;
}

.nav .nav_item {
    padding: 25px 10px;
    font-size: var(--mobile-p);
    cursor: pointer;
    z-index: 1;
    display: block;
    text-align: center;
    color: var(--white)
}


.nav_item:hover {
    background-color: var(--shadow-red);
    color: var(--white)
}

 /* end HEADER*/


/* start MAIN */


 .main-footer-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    height: calc(100vh - 80px);
    width: 100%;
}

.main {
    flex: 1 1 auto;
    width: 100%;
}

/* end MAIN */

/* start FOOTER */

.footer{
    display: flex;
    justify-content: center;
    background-color: var(--red);
    flex-direction: column;
    color: var(--white);
    padding: 15px;
    flex: 0 1 auto;
}

.footer__left-box {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    justify-content: center;
    padding: 45px 15px;
}

.footer__left-box__title {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 15px;
}

.footer__center-box{
    display: flex;
    flex-direction: column;
    flex-basis: 33.3%;
    justify-content: center;
    padding: 65px 15px;
    border-top: 1px solid var(--white)
}

.footer__center-box__cnpj{
    margin-bottom: 10px;
}

.footer__right-box{
    flex-basis: 33.3%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding: 45px 15px;
    border-top: 1px solid var(--white)
}

.footer__right-box__title {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.footer__right-box__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style-type: none;
}

.footer__right-box__grid__item {
    margin-bottom: 5px;
    color: var(--white);
    transition: margin-left .2s;
}

.footer__right-box__grid__item:hover {
    margin-bottom: 5px;
    margin-left: 4px;
}
/* end FOOTER */


@media only screen and (min-width:320px) { 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    
 }

@media only screen and (min-width:480px) { /* smartphones, Android phones, landscape iPhone */ }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    /* start HEADER */

    .header {
        display:flex;
        position: fixed;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
        background-color: var(--red);
        color: var(--white);
        padding: 0;
    }

    .nav { 
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.5rem;
        position: initial;
        flex-direction: row;
        width: auto;
        height: auto;
        transition: 0;
    }

    .logo {
        margin-left: 15px;
    }

    #toggle{
        display: none;
    }

    #hamburguer {
        display: none;
    }

    .nav_item {
        list-style-type: none;
        font-size: var(--mobile-p);
        cursor: pointer;
        flex:0 0 auto;
        padding:0 10px;
        border-radius: 5px;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

 /* end HEADER*/
    
    .footer {
        flex-direction: row;
        align-items: center;
        flex: 0 0 220px;
    }
    
    .footer__left-box {
        flex-basis: 33.3%;
        padding: 15px;
    }
    
    
    .footer__center-box{
        flex-basis: 33.3%;
        padding: 15px;
        border: none
    }
    
    .footer__right-box{
        flex-basis: 33.3%;
        border: none;
        padding: 15px
    }
    

    /* start SCROLL */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track - barra por onde o scroll vai rolar */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle - scroll bar em si*/
::-webkit-scrollbar-thumb {
    background: gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* end SCROLL */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}